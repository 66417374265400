
export default {
  name: 'SectionCreators',
  props: {
    data: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    },
    title: {
      required: false,
      type: String,
      default: ''
    },
    subtitle: {
      required: false,
      type: String,
      default: ''
    }
  },
  computed: {
    titleString() {
      return this.title || 'The'
    },
    subtitleString() {
      return this.subtitle || 'Creators'
    }
  }
}
